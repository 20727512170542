
.download-container {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  position: relative;
  margin: 30px 200px;
}

/* Content container */
.content1 {
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
    position: relative;
  z-index: 1;
  width: 100%;
}

/* Image container */
.image-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: auto;
  z-index: 0;
  margin-top: 30px;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
}

/* Large screens (up to 2560px) */
@media (min-width: 1441px) and (max-width: 2560px) {
  .download-container {
    margin: 30px 700px;
  }

  .content1 {
    padding: 60px;
    height: 400px;
  }

  .image-container {
    width: 35%;
  }
}

/* Medium screens (up to 1440px) */
@media (max-width: 1440px) {
  .download-container {
    margin: 30px 50px;
  }

  .content1 {
    padding: 50px;
  }

  .image-container {
    width: 30%;
  }
}

/* Between large and medium screens (1728px) */
@media (min-width: 1025px) and (max-width: 1728px) {
  .download-container {
    margin: 30px 200px;
  }

  .content1 {
    padding: 40px;
  }

  .image-container {
    width: 30%;
  }
}

/* Tablet screens (up to 1024px) */
@media (max-width: 1024px) {
  .download-container {
    margin: 30px 30px;
  }

  .content1 {
    padding: 40px;
  }

  .image-container {
    width: 32%;
  }
}

/* Small screens and mobile devices (up to 768px) */
@media (max-width: 768px) {
  .download-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 30px 20px;
  }

  .content1 {
    width: 90%;
    padding: 20px;
  }

  .image-container {
    width: 70%;
    margin-bottom: 20px;
    position: static;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .btn {
    margin-bottom: 10px;
  }
}

/* Extra small screens (up to 576px) */
@media (max-width: 576px) {
  .download-container {
    margin: 20px 10px;
  }

  .content1 {
    padding: 15px;
    width: 95%;
  }

  .image-container {
    width: 100%;
  }
}
