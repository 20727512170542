.maintm {
  margin-bottom: 50px;
  height: 500px;
}

.team-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px 0;
 height: 250px;
}

.team-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.cardt-header{
  margin-top: 150px;
  text-align: center;
  color: white;

}
.cardt-body{
  text-align: center;
  color: white;
}



.cardt {
  margin-top: -80px;
  position: absolute;
  transition: transform 0.5s ease, opacity 0.5s ease;
  cursor: pointer;
  opacity: 0.9;
  width: 300px; /* Set the width of the cards */
  height: 320px; /* Set the height of the cards */
  background-color: white; /* Set background color to white */
  border-radius: 15px; /* Rounded borders */
  box-shadow: 0px 4px 20px #faf9f6; /* Add a subtle shadow */
  padding: 20px; /* Add padding inside the cards */
  background-size: cover; /* Or 'contain' */
  background-position: center;
  background-repeat: no-repeat;
}

.cardt-center {
  transform: scale(1.2) translateX(0);
  z-index: 3;
  opacity: 1;
}

.cardt-left {
  transform: scale(0.9) translateX(-150%);
  z-index: 2;
}

.cardt-right {
  transform: scale(0.9) translateX(150%);
  z-index: 2;
}

.cardt-hidden {
  transform: scale(0) translateX(0);
  z-index: 1;
  opacity: 0;
}

.controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 1;
}

.control-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}

.control-btn.left {
  position: absolute;
  left: 0;
  display: none;
}

.control-btn.right {
  position: absolute;
  right: 0;
  display: none;
}

.control-btn:hover {
  color: #333;
}

@media (max-width: 768px) {
  .cardt {
    transform: scale(0.8) translateX(0);
    width: 200px; /* Set the width of the cards */
    height: 350px;
  }
  
.cardt-center {
  transform: scale(1.2) translateX(0);
  z-index: 3;
  opacity: 1;
  font-size: x-small;
}

.cardt-left {
  transform: scale(0.9) translateX(-150%);
  z-index: 2;
  font-size: x-small;
}

.cardt-right {
  transform: scale(0.9) translateX(150%);
  z-index: 2;
  font-size: x-small;
}

.cardt-hidden {
  transform: scale(0) translateX(0);
  z-index: 1;
  opacity: 0;
}

.controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 1;
}

.control-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}

.control-btn.left {
  position: absolute;
  left: 0;
  display: none;
}

.control-btn.right {
  position: absolute;
  right: 0;
  display: none;
}

.control-btn:hover {
  color: #333;
}


  .cardt-center {
    transform: scale(1) translateX(0);
  }

  .team-container-wrapper .control-btn {
    display: none;
  }
}
