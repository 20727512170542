
.container-ab {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
  margin: 10px auto;
  max-width: 80%;
  overflow-y: hidden;
  font-weight: 500;
  background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);

}

.title-ab {
  font-size: 36px;
  margin-bottom: 20px;
}

.description-ab {
  font-size: 18px;
  margin-bottom: 40px;
}

.stats {
  display: flex;

}

.stat {
  font-size: 15px;
  justify-content: space-between;
  border-radius: 10px;
  background-color: hsla(0, 0%, 47%, 0.3);
  box-shadow: 0 4px 8px rgba(19, 19, 19, 0.1);
  padding: 50px;
  margin-right: 20px;
}

.image-ab {
  width: 35vh;
  margin-top: 80px;
  margin-left: 20px;
  height: auto;
  animation: slideInFromLeft 5s ease-out forwards;
  
}


@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}


@media (max-width: 768px) {
  .container-ab {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .right-section-ab {
    order: 1;
    flex-basis: auto;
    width: 100%;
  }

  .left-section-ab {
    order: 2;
    flex-basis: auto;
    width: 100%;
    display: flex;
    justify-items: center;
  }

  .image-ab {
    width: 100%;
    margin: 0 auto;
    border: none;
    
  }
}

@media (max-width: 1024px){
  .image-ab {
    width: 200px;
    margin: 3 auto;
    border: none;
    
  }

}
@media (max-width: 1440px){
  .image-ab {
    width: 200px;
    margin: 1 auto;
    border: none;
    
  }
}
@media (max-width: 1728px){
  .image-ab {
    width: 180px;
    margin: 1 auto;
    border: none;
    
  }
  .stat {
    font-size: 15px;
    background-color: hsla(0, 0%, 47%, 0.3);
    box-shadow: 0 4px 8px rgba(19, 19, 19, 0.1);    justify-content: space-between;
    border-radius: 10px;
    padding: 10px;
    margin-right: 20px;
  }

}
@media (max-width: 2560px){
  .image-ab {
    width: 180px;
    margin: 1 auto;
    border: none;
    
  }
  .stat {
    font-size: 15px;
    background-color: hsla(0, 0%, 47%, 0.3);
    box-shadow: 0 4px 8px rgba(19, 19, 19, 0.1);
        justify-content: space-between;
    border-radius: 10px;
    padding: 10px;
    margin-right: 20px;
  }

}