.rtl .career{
  direction: rtl;
}

.team-red {
  color: #991717;
  padding: 0px 5px 0px 5px;
}


.career {
  background-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);  

}

.careers-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.careers-container h1 {
  margin-top: 100px;
}

.careers-container h1,
.careers-container h2 {
  margin-bottom: 20px;
}

.careers-container p {
  line-height: 1.6;
}

.careers-container ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.job-listing {
  margin-bottom: 30px;
}

.job-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.arrow {
  margin-right: 10px;
  font-size: 1.2em;
}

.job-title {
  margin: 0;
  font-weight: bold;
  color: #faf9f6;
}

.accordion-content {
  padding: 10px;
  border-radius: 5px;
  background-color: #272626;
  border  : 1px solid #000000;
}

.job-listing p {
  color: #faf9f6;
}
.apply-btn {
  background-color: #000c2c;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  transition: background-color 0.3s ease, font-weight 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 15px;
}

.apply-btn:hover {
  font-weight: bold;
  background-color: #000c2c;
  color: #000c2c;
  transform: scale(1.05);
  box-shadow: 5px 10px 15px #000c2c;
  background: #faf9f6
}
p{
  font-weight: 500;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .careers-container {
    padding: 10px;
  }

  .job-listing {
    padding: 10px;
  }

  .job-header {
    align-items: center;
  }

  .job-title {
    font-size: 1.2em;
  }

  .arrow {
    font-size: 1em;
  }

  .container .row {
    flex-direction: column;
    text-align: center; /* Center the text */
  }

  .container img {
    width: 100%;
    height: auto;
    margin-bottom: 20px; /* Space between image and content */
  }

  .col-8, .col-4 {
    width: 100%;
  }

  .apply-btn {
    display: block;
    margin: 20px auto; /* Center the button */
  }
}

@media (max-width: 576px) {
  .job-header {
    padding: 5px;
  }

  .job-title {
    font-size: 1em;
  }

  .arrow {
    font-size: 0.8em;
  }

  .accordion-content {
    padding: 5px;
   
  }
} /* This curly brace was missing or misplaced in the original code */
