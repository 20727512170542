/* Common styles for both LTR and RTL */
.navbar {
    direction: ltr; /* Default direction */
}

.rtl .navbar {
    direction: rtl;
}

.rtl .navbar-nav {
    text-align: right;
}

.rtl .dropdown-menu {
    right: 0;
    left: auto;
}
/* Add this to your global CSS file or the specific CSS file for sections */


  

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px 40px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(to left, #545b69 0%, #1e3164 100%);
}

.logo-img {
    height: 50px;
}

.hands-app {
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1rem; /* Use rem for better scaling */
    line-height: 1.25rem;
}

.navbar-nav {
    display: flex;
    justify-content: center;
    width: 100%;
}

.nav-item {
    position: relative;
    display: flex;
    justify-content: center;
}

.nav-link {
    transition: color 0.3s ease;
    text-align: center;
    color: black;
    position: relative;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: black;
    transition: width 0.3s ease, left 0.3s ease;
}

.nav-link:hover::after,
.nav-item:hover .nav-link::after {
    width: 100%;
    left: 0;
}

.dbtn {
    background-color: #000c2c;
    color: white;
    border-radius: 5px;
    text-align: center;
    box-shadow: 3px 3px 5px #353e57;
    font-size: 1rem; /* Use rem for better scaling */
    line-height: 1.25rem;
    transition: background-color 0.3s ease, font-weight 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 15px;
}


.dbtn:hover {
    font-weight: bold;
    background-color: #000c2c;
    color: #000c2c;
    transform: scale(1.05);
    box-shadow: 5px 10px 15px #000c2c;
    background: #faf9f6;
}

.dropdown-menu {
    background-image: linear-gradient(to left, #545b69 0%, #1e3164 100%);
    background-attachment: fixed;
    background-size: cover;
    border: none;
    border-radius: 0px 0px  5px 5px ; /* Ensure proper units */
}

.dropdown-item {
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
    text-align: center;
    color: white;
    position: relative;
}

.dropdown-item::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: black;
    transition: width 0.3s ease, left 0.3s ease;
}

.dropdown-item:hover::after,
.dropdown-item:hover {
    width: 100%;
    left: 0;
    background-color: transparent;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .navbar-nav {
        justify-content: space-around;
    }

    .nav-item {
        margin: 0 10px;
    }

    .logo-img {
        height: 45px;
    }
}

@media (max-width: 768px) {
    .navbar-nav {
        flex-direction: column;
        text-align: center;
    }

    .nav-item {
        margin: 10px 0;
    }

    .d-flex {
        justify-content: center;
        padding-right: 15px;
    }

    .dropdown-toggle::after {
        margin-left: 5px;
    }
}

/* Additional media query for mobile */
@media (max-width: 576px) {
    .nav-link,
    .dbtn {
        font-size: 0.9rem; /* Adjust font size for small screens */
        padding: 5px 10px; /* Adjust padding for small screens */
    }
}
