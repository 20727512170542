/* home.css */
.home-container {
  padding: 90px 40px; /* Adjusted for spacing */
  max-width: 1200px;
  margin: 0 auto;
}

/* Animation for fading in and sliding up */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation */
.text-section h1, .text-section p {
  opacity: 0; /* Initially hidden */
  animation: fadeInUp 3s ease-in-out forwards; /* Animation */
}

.text-section h1 {
  animation-delay: 0.5s; /* Delay for h1 */
}

.text-section p {
  animation-delay: 0.9s; /* Delay for p */
}

.btn0 {
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #000c2c;
  cursor: pointer;
  margin: 10px; 
  position: relative;
  text-decoration: none;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn0:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 30%;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.btn0:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000c2c;
  text-decoration: none;
  left: 0;
  top: 0;
  border-radius: 10px;
}


.imgcon img {
  width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: Add some border radius */
  animation: bounce 4s ease infinite; /* Apply the bouncing animation continuously */
}


.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  align-items: center; /* Center align items vertically */
  gap: 20px; /* Space between columns */
}

.text-section {
  text-align: left; /* Align text to the left */
}

.imgcon img {
  width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: Add some border radius */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* Stack columns on smaller screens */
  }
}
