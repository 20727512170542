.features-title {
    text-align: center;
    font-size: 30px;
    padding-bottom: 15px;
    margin-top: 100px;
  }

  
  
  .card1 {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 70px;
    flex-wrap: wrap;
    max-width: 1200px; 
    margin: 0 auto; 
    
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    border-color: #000000;
    width: 280px;
    border-radius: 10px;
    background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
    color: #F6F6F6;
}

  
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  border-color: #F6F6F6;
  width: 280px;
  border-radius: 20px;
  background-color: white;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.card:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
  box-shadow: 0 8px 16px #00000033; /* Stronger shadow effect on hover */
}

.card h4 {
  margin-top: 20px;
}

.card p {
  font-size: 15px;
  padding: 0px 20px;
}

.circle1 {
  width: 80px;
  height: 80px; 
  margin-top: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../Assets/Ellipse 24.png'); 
  background-size: cover; 
  background-position: center; 
}

.circle1 img {
  width: 60px;
  height: 60px;
  padding: 5px;
}



  .card h4 {
    margin-top: 20px;
  }
  
  .card p {
    font-size: 15px;
    padding: 0px 20px;
  }
  
  .circle1 {
    width: 80px;
    height: 80px; 
    margin-top: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../Assets/Ellipse 24.png'); 
    background-size: cover; 
    background-position: center; 
  }
  
  .circle1 img {
    width: 60px;
    height: 60px;
    padding: 5px;
  }
  
  @media (max-width: 768px) {
    .card1 {
      flex-direction: column;
      align-items: center;
      max-width: 100%; 
    }
    @media (max-width: 768px) {
      .card1 {
        flex-direction: column;
        align-items: center;
        max-width: 100%; 
      }
    }
  }
  